// Entry point for the build script in your package.json

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Stimulus
import { Application } from "stimulus";
import ExpandableController from "./controllers/ExpandableController";
import FloatingElementController from "./controllers/FloatingElementController";
import HeaderController from "./controllers/HeaderController";
const application = Application.start();
application.register("expandable", ExpandableController);
application.register("floating-element", FloatingElementController);
application.register("header", HeaderController);

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
