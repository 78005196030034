import React, { ReactNode } from "react";

interface Props {
  grant: GrantsList.Grant;
  categories: GrantsList.Category[];
  strings: GrantsList.Localizations;
}

function formatNumber(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export default function GrantsListItem(props: Props) {
  const { grant, categories, strings } = props;

  const categoryName = () => {
    const category = categories.filter((c) => c.id == grant.category_id)[0];
    if (category) {
      return category.full_name;
    } else {
      return "";
    }
  };

  const wrapLink = (content: ReactNode) => {
    if (!grant.featured) {
      return content;
    }

    return <a href={grant.url}>{content}</a>;
  };

  return (
    <li className="grant">
      {wrapLink(
        <div className="grant-inner">
          <div className="info">
            <span className="column">
              <span>{grant.year}</span>
              <span>{strings.month_names[grant.month]}</span>
            </span>
            <span className="column recipient">
              <span className="name">{grant.name}</span>
              <span>{grant.title}</span>
            </span>
            <span className="column">
              <span>{categoryName()}</span>
              <span>
                {strings.currency_name} {formatNumber(grant.sum)}
              </span>
            </span>
          </div>
          {grant.featured && (
            <div className="featured">
              {grant.image && (
                <div className="image">
                  <img src={grant.image} alt={grant.title} />
                </div>
              )}
              <div className="text">
                <p>{grant.excerpt}</p>
                <p className="read-more">{strings.read_more}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </li>
  );
}
