import React from "react";

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (string) => void;
  disabled?: boolean;
  errors?: string[];
}

export default function TextField(props: Props) {
  const { id, name, label, value, onChange, errors, disabled } = props;

  const classes = ["field"];
  if (errors) {
    classes.push("with-errors");
  }

  return (
    <div className={classes.join(" ")}>
      <label htmlFor={id}>
        {label}:{errors && <span className="error">({errors[0]})</span>}
      </label>
      <input
        id={id}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
