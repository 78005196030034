import React from "react";

interface Props {
  event: SignupEvent;
  signup: Signup;
  submitted: boolean;
}

export default function EventStatus(props: Props) {
  const { event, signup, submitted } = props;

  if (!submitted) {
    if (event.full && signup.status !== "accepted") {
      return <div className="event-status">Arrangementet er fulltegnet.</div>;
    } else {
      return (
        <div className="event-status">
          Vi ber om svar innen {event.deadline}
          <br />
          {event.open && event.limited && "Begrenset antall plasser."}
          {!event.open && "Påmeldingen er stengt"}
        </div>
      );
    }
  }

  if (signup.status === "accepted") {
    return (
      <React.Fragment>
        <div className="event-status">Takk!</div>
        <p>Du har fått en bekreftelse fra oss på din e-postadresse.</p>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="event-status">Takk for at du svarte.</div>
        <p>
          Du har takket nei til invitasjonen. Hvis du likevel finner tid og
          mulighet før svarfristen vår, kan du bruke påmeldingsknappen i
          invitasjonen.
        </p>
      </React.Fragment>
    );
  }
}
