import { useEffect, useRef } from "react";
import debounce from "debounce";

type Props = {
  state: GrantsList.State;
  dispatch: (action: GrantsList.Action) => void;
  strings: GrantsList.Localizations;
};

function useValue(value: string) {
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    if (ref.current && ref.current.value !== value) {
      ref.current.value = value;
    }
  }, [value]);
  return { ref: ref, defaultValue: value };
}

export default function SearchBar({ state, dispatch, strings }: Props) {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "changeSearchQuery", payload: evt.target.value });
  };

  const handleToggle = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "toggleSearch" });
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        autoFocus
        name="q"
        placeholder={strings.search_all_grants}
        onChange={debounce(handleChange, 250)}
        {...useValue(state.searchQuery)}
      />
      <button type="button" className="close" onClick={handleToggle}>
        Close
      </button>
    </div>
  );
}
