import { Controller } from "stimulus";
import WindowAnimation from "../lib/WindowAnimation";

export default class extends Controller {
  floatableTarget: HTMLElement;
  hasFloatableTarget: boolean;
  handle: number;

  static get targets() {
    return ["floatable"];
  }

  connect() {
    const topMargin = 120;
    const minDistance = 500;
    let floatableHeight = 0,
      contentHeight = 0,
      contentStart = 0;

    if (!this.hasFloatableTarget) {
      return;
    }

    Array.from(this.element.getElementsByTagName("img")).forEach((img) => {
      if (!img.complete) {
        img.addEventListener("load", () => {
          document.dispatchEvent(new Event("layoutchanged"));
        });
      }
    });

    this.handle = WindowAnimation.register({
      resize: () => {
        const rect = this.element.getBoundingClientRect();
        const floatableRect = this.floatableTarget.getBoundingClientRect();

        floatableHeight = floatableRect.height;
        contentHeight = rect.height;
        contentStart = rect.y + window.pageYOffset;
      },
      scroll: (offset: number) => {
        if (contentHeight - floatableHeight > minDistance) {
          if (offset >= contentStart) {
            this.element.classList.add("fadein");
          } else {
            this.element.classList.remove("fadein");
          }

          if (
            offset >
            contentStart + contentHeight - (floatableHeight + topMargin)
          ) {
            this.element.classList.add("bottom");
            this.element.classList.remove("fixed");
          } else if (offset > contentStart - topMargin) {
            this.element.classList.add("fixed");
            this.element.classList.remove("bottom");
          } else {
            this.element.classList.remove("fixed");
            this.element.classList.remove("bottom");
          }
        } else {
          this.element.classList.remove("fixed");
          this.element.classList.remove("bottom");
          this.element.classList.remove("fadein");
        }
      }
    });
  }

  disconnect() {
    WindowAnimation.clear(this.handle);
  }
}
