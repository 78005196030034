import React from "react";

interface Props {
  signup: Signup;
  setStatus: (status: SignupStatus) => void;
}

export default function Status(props: Props) {
  const { signup, setStatus } = props;
  return (
    <div className="field status-options radio-buttons">
      <label>
        <input
          id="signup-status-accepted"
          type="radio"
          name="signup[status]"
          value="accepted"
          checked={signup.status === "accepted"}
          onChange={() => setStatus("accepted")}
        />
        Ja, jeg kommer gjerne!
      </label>
      <label>
        <input
          id="signup-status-declined"
          type="radio"
          name="signup[status]"
          value="declined"
          checked={signup.status === "declined"}
          onChange={() => setStatus("declined")}
        />
        Jeg har dessverre ikke anledning til å komme.
      </label>
    </div>
  );
}
