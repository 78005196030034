import { Controller } from "stimulus";
import WindowAnimation from "../lib/WindowAnimation";

export default class extends Controller {
  searchInputTarget: HTMLInputElement;
  handle: number;

  static get targets() {
    return ["searchInput"];
  }

  connect() {
    this.handle = WindowAnimation.register({
      scroll: (offset) => {
        if (offset > 100) {
          this.element.classList.add("scrolled");
        } else {
          this.element.classList.remove("scrolled");
          if (!this.element.classList.contains("searching")) {
            this.element.classList.remove("expanded");
          }
        }
      }
    });
  }

  disconnect() {
    WindowAnimation.clear(this.handle);
  }

  toggleExpanded() {
    if (this.element.classList.contains("expanded")) {
      this.element.classList.remove("expanded");
      this.element.classList.add("contract");
      setTimeout(() => {
        this.element.classList.remove("contract");
      }, 250);
      this.element.classList.remove("searching");
    } else {
      this.element.classList.add("pre-expand");
      window.requestAnimationFrame(() => {
        this.element.classList.remove("pre-expand");
        this.element.classList.add("expanded");
        this.element.classList.add("searching");
      });
    }
  }

  showSearch(evt: Event) {
    evt.preventDefault();
    if (!this.element.classList.contains("searching")) {
      this.element.classList.add("searching");
      this.element.classList.add("expanded");
    }
    window.requestAnimationFrame(() => {
      this.searchInputTarget.focus();
    });
  }
}
