import React from "react";
import ReactPaginate from "react-paginate";

interface Props {
  hrefBuilder: () => void;
  onPageChange: (selectedItem: { selected: number }) => void;
  page: number;
  pageCount: number;
  strings: PaginationLocalizations;
}

export default function Pagination(props: Props) {
  const { hrefBuilder, onPageChange, page, pageCount, strings } = props;

  return (
    <ReactPaginate
      hrefBuilder={hrefBuilder}
      forcePage={page - 1}
      disableInitialCallback={true}
      onPageChange={onPageChange}
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      nextLabel={strings.next_page}
      previousLabel={strings.previous_page}
      activeClassName="current"
      containerClassName="pagination"
    />
  );
}
