import React from "react";

import EventStatus from "./SignupForm/EventStatus";
import Form from "./SignupForm/Form";

interface Props {
  authenticity_token: string;
  event: SignupEvent;
  signup: Signup;
  submitted: boolean;
  type: SignupFormType;
  url: string;
}

export default function SignupForm(props: Props) {
  const { event, signup, submitted } = props;

  const showForm =
    !submitted && event.open && (!event.full || signup.status === "accepted");

  return (
    <div className="signup-form">
      <EventStatus {...props} />
      {showForm && <Form {...props} />}
    </div>
  );
}
