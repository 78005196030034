import React, { useState } from "react";
import PhotoAlbum, { Photo } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

interface Props {
  linkTitle: string;
  images: Photo[];
}

export default function Gallery(props: Props) {
  const [index, setIndex] = useState(-1);

  const { images, linkTitle } = props;

  const handleOpen = () => {
    setIndex(0);
  };

  return (
    <section className="gallery">
      <button onClick={handleOpen} className="icon open-button">
        {linkTitle}
      </button>
      <PhotoAlbum
        layout="rows"
        photos={images}
        onClick={({ index: current }) => setIndex(current)}
      />
      <Lightbox
        plugins={[Captions]}
        index={index}
        slides={images}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </section>
  );
}
